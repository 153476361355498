.Clock-day {
    color: black;
    font-size: 250px;
    text-align: center;
    background-color: white;
}

.Clock-night {
    color: white;
    font-size: 250px;
    text-align: center;
    background-color: black;
}