.TodayWeather{
    height: 376px;
    width: 376px;
    margin: 8px;
    background-color: #ababab;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.TodayWeather-title{
    margin: 8px;
    font-size: 64px;
    color: black;
    display: flex;
    justify-content: center;
    text-shadow: 5px 0 0 yellow, 0 -5px 0 yellow, 0 5px 0 yellow, -5px 0 0 yellow;
}

.TodayWeather-temp{
    margin: 8px;
    font-size: 128px;
    color: black;
    display: flex;
    justify-content: center;
    text-shadow: 5px 0 0 yellow, 0 -5px 0 yellow, 0 5px 0 yellow, -5px 0 0 yellow;
}

.TodayWeather-feel{
    margin: 8px;
    font-size: 36px;
    color: black;
    display: flex;
    justify-content: center;
    text-shadow: 5px 0 0 yellow, 0 -5px 0 yellow, 0 5px 0 yellow, -5px 0 0 yellow;
}