.DayForecast{
    height: 180px;
    width: 180px;
    margin: 8px;
    background-color: #ababab;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DayForecast-date{
    margin: 8px;
    font-size: 32px;
    color: black;
    display: flex;
    justify-content: center;
    text-shadow: 5px 0 0 yellow, 0 -5px 0 yellow, 0 5px 0 yellow, -5px 0 0 yellow;
}

.DayForecast-temp{
    margin: 8px;
    font-size: 64px;
    color: black;
    display: flex;
    justify-content: center;
    text-shadow: 5px 0 0 yellow, 0 -5px 0 yellow, 0 5px 0 yellow, -5px 0 0 yellow;
}