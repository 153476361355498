.Day-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.Day-text {
    color: black;
    font-size: 125px;
    text-align: center;
    margin: 8px;
}

.Day-icontext {
    display: flex;
    flex-direction: row;
    align-items: center;
}